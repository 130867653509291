import "./App.scss";
import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createHashRouter, RouterProvider } from "react-router-dom";

import WebsiteLayout from "./components/layout/WebsiteLayout";
import UserWebsite from "./pages/website/UserWebsite";
import FullScreenLoader from "./components/loaders/FullScreenLoader";
import { AppProvider } from "./context/SharedData";


function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <WebsiteLayout />,
      children: [
        {
          path: "/",
          element: <UserWebsite />,  },
      ],
    },


  ]);
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Suspense>
  );
}

export default App;
