// import { message } from "antd";
// const alert = (type, content, config) => {
//   message.config(config);
//   message[type](content);
// };

// export default alert;


import { message } from "antd";

let currentAlert = null;

const alert = (type, content, config) => {
  // Destroy the current message if it exists
  if (currentAlert) {
    message.destroy();
    currentAlert = null;
  }

  // Show the new alert message
  message.config(config);
  currentAlert = message[type](content);

  // Return the message reference if needed
  return currentAlert;
};

export default alert;
